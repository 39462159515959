import React from "react";
import "./viewinvoices.styles.css";
export const ViewInvoices = () => {
  return (
    <div className="viewinvoices-container">
      <header className="reports-header viewinvoices-header">
        All Invoices
      </header>
    </div>
  );
};
