export const SliderData = [
  {
    image: "images/slider-images/lswt2.mp4",
  },

  {
    image:
      "https://images.unsplash.com/photo-1485291571150-772bcfc10da5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80",
  },
  {
    image: "images/slider-images/2.jpg",
  },
  {
    image: "images/slider-images/3.jpg",
  },
  {
    image: "images/slider-images/4.jpg",
  },
  {
    image: "images/slider-images/5.jpg",
  },
];
