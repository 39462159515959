export const teamList = [
  {
    name: "Jeramiah Morrison",
    position: "Owner / Installer",
    experience: "15 Years",
    specialization: ["Tinting", "PPF", "Wraps"],
    about: "Founder, Owner & Operator",
    picture: "images/teampics/miah.jpg",
  },

];
