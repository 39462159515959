export const newsData = [
  {
    header: "new product",
    img: "https://images.unsplash.com/photo-1554708893-e11aa45b9bbf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8d2luZG93JTIwdGludHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium nesciunt libero explicabo rerum quam mollitia delectus laboriosam cupiditate impedit beatae nisi, eligendi, id tempora perferendis distinctio vel officiis praesentium necessitatibus.",
  },
  {
    header: "wrap color",
    img: "https://images.unsplash.com/photo-1502877338535-766e1452684a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y2FyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium nesciunt libero explicabo rerum quam mollitia delectus laboriosam cupiditate impedit beatae nisi, eligendi, id tempora perferendis distinctio vel officiis praesentium necessitatibus.",
  },
  {
    header: "drying time",
    img: "https://images.unsplash.com/photo-1502877338535-766e1452684a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y2FyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium nesciunt libero explicabo rerum quam mollitia delectus laboriosam cupiditate impedit beatae nisi, eligendi, id tempora perferendis distinctio vel officiis praesentium necessitatibus.",
  },
  {
    header: "ppf info",
    img: "https://images.unsplash.com/photo-1502877338535-766e1452684a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y2FyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium nesciunt libero explicabo rerum quam mollitia delectus laboriosam cupiditate impedit beatae nisi, eligendi, id tempora perferendis distinctio vel officiis praesentium necessitatibus.",
  },
];
